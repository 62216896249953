@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@500&family=Poppins:wght@300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.background-clip {
  /* clip-path: polygon(58% 0, 100% 0, 100% 100%, 20% 100%); */
  /* clip-path: polygon(72% 0, 100% 0, 100% 100%, 36% 100%); */
  clip-path: polygon(38% 0, 100% 0, 100% 100%, 0 100%);
}

.nav-link {
  @apply relative;
}

.nav-link:hover::before {
  content: '<';
  position: absolute;
  left: -9px;
  @apply font-mono text-red;
}

.nav-link:hover::after {
  content: '/>';
  position: absolute;
  @apply font-mono text-red;
}

.small-tags::before {
  content: '<small>';
  @apply text-red;
}

.small-tags::after {
  content: '</small>';
  @apply text-red;
}

.red-btn-glow {
  @apply transition-transform ease-in-out hover:scale-110 hover:before:absolute hover:before:-inset-1 hover:before:z-[-10] hover:before:rounded-lg hover:before:bg-red hover:before:blur-sm hover:before:filter;
}

.footer-heading {
  @apply text-xl  font-bold;
}

.footer-link {
  @apply transition-colors ease-in hover:text-red hover:underline hover:underline-offset-2;
}

.section-header::before {
  content: '<h2>';
  @apply text-red;
}

.section-header::after {
  content: '</h2>';
  @apply text-red;
}

@layer utilities {
  .reversed {
    direction: rtl;
  }
}

.not-reversed {
  direction: ltr;
}

.work-blip::before {
  content: '';
  filter: blur(3px);
  @apply absolute top-1/2 left-1/2 z-0 h-6 w-6 -translate-y-1/2 -translate-x-1/2 transform rounded-full bg-red;
}

.portrait::before {
  content: '';
  @apply absolute inset-0 z-[-1] -translate-x-1 translate-y-1 transform rounded-lg border-2 border-red;
}

.project-tool:not(:last-child)::after {
  content: '•';
  @apply relative top-0.5 ml-2 h-4 text-lg text-red;
}

.swiper-container {
  height: 400px;
  /* width: 100%; */
}

.swiper {
  margin: 0 !important;
  display: flex !important;
}
